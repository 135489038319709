var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-layout", [
    _c(
      "div",
      { staticClass: "gx-custom-registration gx-main-content-wrapper" },
      [
        _c("div", { staticClass: "gx-login-container" }, [
          _c(
            "div",
            { staticClass: "gx-login-content" },
            [
              _c("div", { staticClass: "gx-login-header gx-text-center" }, [
                _c("h1", { staticClass: "gx-login-title" }, [
                  _vm._v("Register your club with SubsApp")
                ])
              ]),
              _vm.message
                ? _c("a-alert", {
                    attrs: { type: "error", message: _vm.message, banner: "" }
                  })
                : _vm._e(),
              _c(
                "a-form",
                {
                  staticClass: "gx-login-form gx-form-row0",
                  attrs: { form: _vm.form }
                },
                [
                  _c(
                    "a-form-item",
                    { staticClass: "gx-m-0", attrs: { label: "Club Name" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.fields.club_name,
                            expression: "fields.club_name"
                          }
                        ],
                        attrs: { placeholder: "The name of your club" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { staticClass: "gx-m-0", attrs: { label: "First Name" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.fields.first_name,
                            expression: "fields.first_name"
                          }
                        ],
                        attrs: { placeholder: "Your first name" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { staticClass: "gx-m-0", attrs: { label: "Last Name" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.fields.last_name,
                            expression: "fields.last_name"
                          }
                        ],
                        attrs: { placeholder: "Your last name" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { staticClass: "gx-m-0", attrs: { label: "Email" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.fields.email,
                            expression: "fields.email"
                          }
                        ],
                        attrs: { placeholder: "Email" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { staticClass: "gx-m-0", attrs: { label: "Password" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.fields.password,
                            expression: "fields.password"
                          }
                        ],
                        attrs: { placeholder: "Password", type: "password" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "Confirm Password" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.fields.c_password,
                            expression: "fields.c_password"
                          }
                        ],
                        attrs: {
                          placeholder: "Confirm Password",
                          type: "password"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { staticClass: "gx-text-center" },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            block: "",
                            "html-type": "submit"
                          },
                          on: { click: _vm.handleForm }
                        },
                        [_vm._v("\n              Register\n            ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "gx-text-center" },
                    [_c("router-link", { attrs: { to: "/" } })],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }